<template>
    <div class="goals">
        <div class="goals-menu">
            <div class="option" @click="create_goal">
                <i class="material-icons">supervised_user_circle</i>
                <p>צור יעד חדש</p>
            </div>
            <div class="option" @click="state = 'current'">
                <i class="material-icons">supervised_user_circle</i>
                <p>יעדים נוכחיים</p>
            </div>
            <div class="option" @click="state = 'past'">
                <i class="material-icons">supervised_user_circle</i>
                <p>יעדים קודמים</p>
            </div>
            <div class="option" @click="state = 'stats'">
                <i class="material-icons">supervised_user_circle</i>
                <p>יעדים מצטבר</p>
            </div>
        </div>
        <div class="goals-main">
            <NewGoal v-if="state == 'new'" :new_goal="new_goal" @close="state='current'"/>
            <Current v-if="state == 'current'" @edit="edit_goal"/>
            <Past v-if="state == 'past'" @edit="edit_goal"/>
            <Stats v-if="state == 'stats'"/>
        </div>
    </div>
</template>

<script>
import NewGoal from '../../components/Admin/Goals/NewGoal.vue';
import Current from '../../components/Admin/Goals/Current.vue';
import Past from '../../components/Admin/Goals/Past.vue';
import Stats from '../../components/Admin/Goals/Stats.vue';

import { ref } from '@vue/reactivity'
import Swal from 'sweetalert2';
import { projectFirestore } from '../../firebase/config';
import { slide_pop_successs } from '../../Methods/Msgs';
export default {
components:{NewGoal, Current, Past, Stats},
setup(){
    const state = ref(null);
    const agents = ref([]);
    const new_goal = ref({
        title: '',
        type: '',
        makat_type: '',
        date_start: '',
        date_end: '',
        products: [],
        agents: []
    })
    const temp_makats = ref('');

    const check_makats = async () => {
        new_goal.value.products = [];
        let products = [];
        if(new_goal.value.makat_type == 'מקט'){
            let makat_arr = temp_makats.value.split(' ');
            
            for(let i =0; i< makat_arr.length; i++){
                if(makat_arr[i].length != 4){
                    Swal.fire({icon: 'error', title: 'שגיאת מקט', text:`מקט ${makat_arr[i]} איננו תקני`})
                    return
                }
                const check = await projectFirestore.collection('Products').doc(makat_arr[i]).get()
                if(!check.exists){
                    Swal.fire({icon: 'error', title: 'שגיאת מקט', text:`מקט ${makat_arr[i]} לא נמצא במערכת`})
                    return
                }else{
                    products.push(check.data())
                }
            }
        }
        if(new_goal.value.makat_type == 'מרכז'){
            const docs = await projectFirestore.collection('Products').where('paritMerakez', '==', temp_makats.value).get()
            if(docs.empty){
                Swal.fire({
                    icon: 'error',
                    title: 'שגיאה',
                    text: `לא נמצאו פריטים תחת המקט המרכז ${temp_makats.value}`
                })
            }else{
                products = docs.docs.map(doc => doc.data())
            }
        }
        if(products.length > 0){
            products.forEach(product => {
                new_goal.value.products.push({
                    makat: product.makat,
                    image: product.image,
                    name: product.name,
                    boxSize: product.boxSize
                })
            })
        }
        slide_pop_successs("המקטים נבדקו בהצלחה!")
        get_agents();
    }

    const edit_goal = (e) => {
        new_goal.value = e;
        state.value = 'new';
    }

    const create_goal = () => {
        new_goal.value = {
            title: '',
            type: '',
            makat_type: '',
            date_start: '',
            date_end: '',
            products: [],
            agents: []
        }
        state.value = 'new';
    }

     
    return{
        state, new_goal, edit_goal, create_goal
    }
}
}
</script>

<style scoped>
.goals{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 375px calc(100% - 375px);
    grid-template-rows: 100%;
}
.goals-menu{
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow-y: auto;
    padding: 5px;
}
.goals-main{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.option{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 26px;
    background: var(--secondary);
    padding: 0 5px 0 0;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    flex-shrink: 0;
}
.option:hover p{
    margin-right:25px;
    color: var(--yellow);
}
.option i {
    margin-left: 25px;
    color: var(--light);
    font-size: 33px;
}
.option p{
    color:var(--light);
    transition: 0.25s;
}
@media only screen and (max-width: 800px) {
    .goals{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows:  150px calc(100% - 150px);
    }
}
</style>